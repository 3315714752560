@font-face {
	font-family: "Ubuntu";
	src: url("./assets/fonts/ubuntu/Ubuntu-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "Ubuntu";
	src: url("./assets/fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Ubuntu";
	src: url("./assets/fonts/ubuntu/Ubuntu-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "Ubuntu";
	src: url("./assets/fonts/ubuntu/Ubuntu-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

* html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	#lightbox {
		position: fixed;
		z-index: 1000;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .85);
		display: none;
	}

	#lightbox.active {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#lightbox img {
		max-width: 90%;
		max-height: 80%;
		padding: 4px;
		background-color: black;
		border: 2px solid white;
	}

	#lightbox button {
		position: absolute;
		top: 3%;
		right: 5%;
		transform: scale(2);
		color: white;
		display: block;
	}
}